import { Col, Form, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  displayErrorAlert,
  displaySuccessAlert,
} from "../../components/alert/alert";
import baseService from "../../core/base-service";
import StorageBox from "../../core/storage";
import { apiUrl } from "../../core/url";
import logo from "./../../assets/images/logo.png";
import AuthSlider from "./AuthSlider";
import { LiaLanguageSolid } from "react-icons/lia";
import { CheckProgress } from "./components/CheckProgress";

export default function SecondLogin() {
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userFullName, setUserFullName] = useState<string>("");


  useEffect(() => {
    const storedFullName = StorageBox.getUser()
    console.log("the store user in the secondlogin is >>", storedFullName)
    if (storedFullName){
        const fullName = storedFullName.data.fullName;
         const firstName = fullName.split(" ")[0];  // Extract the first name
        setUserFullName(firstName);
    } 
  }, []);

  const navigate = useNavigate();

  async function sign_in() {
    try {
      if (email === "") {
        displayErrorAlert("Please input your email address");
        setEmailError(true);
        return;
      }

      setEmailError(false);
      if (password === "") {
        setPasswordError(true);
        return;
      }
      setPasswordError(false);
      setLoading(true);

      const response: any = await baseService.post(apiUrl.login, {
        appName: "dodoWeb",
        email: email,
        password: password,
      });
      console.log("the response from the second Login is >>", response)
      setLoading(false);
      displaySuccessAlert("Successfully Logged In");
      const user = response.data?.data;
      const token = response.data?.token;
      const companyDocs = response.data?.company ?? null;
      const billingInformation = response.data?.billing;

      // console.log(user, token, companyDocs, billingInformation);

      StorageBox.setUser(user);
      StorageBox.setToken(token);
      StorageBox.setCompanyDocs(companyDocs);
      StorageBox.setBillingInformation(billingInformation);
      navigate("/company-information");
    } catch (error: any) {
      setLoading(false);
      displayErrorAlert(
        error.response?.data?.message ?? "An error ocurred while logging in"
      );
    }
  }

  const [form] = Form.useForm();

  return (
    <React.Fragment>
        <FirstTimeLogin
        form={form}
        signIn={sign_in}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        loading={loading} 
        userFullName={userFullName}/>
    </React.Fragment>
  );
}

function FirstTimeLogin({ form, signIn, email, setEmail, password, setPassword, loading,userFullName }: any){
  return(
    <div className=" min-h-[100vh] flex justify-center items-center">
      <div className="w-full max-w-2xl flex flex-col items-center justify-center h-[70vh] bg-cardBg rounded-3xl shadow-md">
        <img
          src={logo}
          className="h-8 mb-5 cursor-pointer"
          onClick={() => window.open("https://usedodo.com/", "_blank")}/>
        <p className="text-4xl font-semibold">Welcome {userFullName}</p>
        <span className="py-4">Login into your account</span>
        <CheckProgress progress={2} />
          <LoginForm
           form={form}
           signIn={signIn}
           email={email}
           setEmail={setEmail}
           password={password}
           setPassword={setPassword}
           loading={loading}/>
        {/* <hr  /> */}
        <div className="w-1/2 border my-5"></div>

        <div>
          <Link to="/language" className="flex items-center font-semibold text-sm gap-x-1 hover-btnPrimary">
                <span>Select Language</span>
                <LiaLanguageSolid />
          </Link>
        </div>
      </div>
    </div>
  )
}



function LoginForm({ form, signIn, email, setEmail, password, setPassword, loading }: any) {
  return (
    <Form className="w-[50%]" form={form} onFinish={signIn}>
      <div className="mt-5">
        <label htmlFor="email">Email</label>
        <Form.Item
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input
            placeholder="name@work-email.com"
            allowClear
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="mt-5">
        <label htmlFor="password">Password</label>
        <Form.Item
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password
            placeholder="* * * * * * * *"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
      </div>
      <Link to="/forgot-password" className="hover-btnPrimary">Forgot password?</Link>
      <div className="mt-10">
        <Form.Item>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            disabled={loading}
            type="submit"
          >
            {loading ? <Spin style={{ color: "white" }} /> : "Sign In"}
          </button>
        </Form.Item>
      </div>
    </Form>
  );
}