import React from "react";

const CustomCard: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={`max-w p-6 bg-white border border-gray-200 rounded-lg shadow-sm  ${className}`}
    >
      {children}
    </div>
  );
};

export default CustomCard;
