import {
  BellAlertIcon,
  CalendarDaysIcon,
  EnvelopeOpenIcon,
  MegaphoneIcon,
  PhoneArrowDownLeftIcon,
  PhoneIcon,
  ServerStackIcon,
  Squares2X2Icon,
  VideoCameraIcon,
  ChatBubbleBottomCenterIcon,
  UsersIcon,
  BuildingOfficeIcon,
  UserMinusIcon
} from "@heroicons/react/24/outline";
import { ForwardRefExoticComponent, RefAttributes, SVGProps } from "react";


const navigationLinks: {
  name: string;
  Icon: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & RefAttributes<SVGSVGElement>
  >;
  link: string;
  disabled?: boolean;
  isExpandable?: boolean;
  subLinks?: {
    name: string;
    link: string;
    icon: ForwardRefExoticComponent<
      Omit<SVGProps<SVGSVGElement>, "ref"> & {
        title?: string | undefined;
        titleId?: string | undefined;
      } & RefAttributes<SVGSVGElement>
    >;
  }[];
}[] = [
  {
    name: "Home",
    Icon: Squares2X2Icon,
    link: "/dashboard",
  },
  {
    name: "Directory",
    Icon: PhoneIcon,
    link: "/users",
  },
  {
    name: "History",
    Icon: CalendarDaysIcon,
    link: "/call-history",
    subLinks: [
      { name: "Voice", link: "/voice-history", icon: PhoneArrowDownLeftIcon },
      { name: "Video", link: "/video-history", icon: VideoCameraIcon },
      { name: "Messages", link: "/messages-history", icon: ChatBubbleBottomCenterIcon },
      // {
      //   name: "Messages",
      //   link: "/message-history",
      //   icon: ChatBubbleOvalLeftEllipsisIcon,
      // },
    ],
  },
  {
    name: "Notice Board",
    Icon: BellAlertIcon,
    link: "/notice-board",
  },
  {
    name: "Organization",
    Icon: EnvelopeOpenIcon,
    link: "/organization",
    subLinks: [
      { name: "Company", link: "/organization", icon: BuildingOfficeIcon },
      { name: "Admin", link: "/user-admin", icon: UsersIcon },
      { name: "Supervisors",link: "/user-supervisor", icon: UserMinusIcon }
    ],
  },
  {
    name: "Billing",
    Icon: CalendarDaysIcon,
    link: "/billing",
  },
  {
    name: "Storage",
    Icon: ServerStackIcon,
    link: "/storage",
  },
  // {
  //   name: "Advertising",
  //   Icon: MegaphoneIcon,
  //   link: "/advertisement",
  // },
];
export default navigationLinks;
