import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Avatar, Input,message } from "antd";
import StorageBox from "../../core/storage";
import { FiSearch } from "react-icons/fi";

import * as React from "react";

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../../componentUI/components/ui/navigation-menu";
import { EditProfile } from "../../pages/admin/Edit-Admin";
import { apiUrl, baseUrl } from "../../../src/core/url";
import baseService from "../../../src/core/base-service";

interface IUser {
  id: number;
  fullName: string;
  phone: string;
  extension: string;
  owner: number;
  role: string;
  country: string | null;
  department: string | null;
  branch: string | null;
  email: string;
  verified: number;
  token: string;
  otp: string;
  expired: string;
  created_at: string;
  updated_at: string;
  userJID: string;
  fcmToken: string;
  workspace: {
    id: number;
    workspaceName: string;
    email: string;
    logo: string | null;
    created_at: string;
    updated_at: string;
  };
  profile: {
    id: number;
    logo: string | null;
    status: string;
    user_id: number;
    created_at: string;
    updated_at: string;
  };
}

export default function TopNavBar() {
  const user: IUser = StorageBox.getUser();
  console.log("the user on the top navbar", user)
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<any[]>([]);
  const [filter, setFilter] = React.useState("user");
  console.log("the user is ", user)
  const { Search } = Input;

  const getUserInitials = (fullName?: string) => {
    if (!fullName) return '';
    
    const names = fullName.split(' ');
    if (names.length >= 2) {
      return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`.toUpperCase();
    }
    return fullName.charAt(0).toUpperCase();
  };

  function capitalizeFirstLetter(str?: string) {
    if (!str || typeof str !== "string") return ""; // Handle undefined or non-string inputs
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Provide default values if fullName or role are missing
  const displayName = capitalizeFirstLetter(user?.fullName) || "User";
  const displayRole = capitalizeFirstLetter(user?.role) || "Role";

  // Handle search query input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

   // Handle search execution
   async function handleSearch() {
    if (searchQuery.trim() === "") {
      message.warning("Please enter a search query");
      return;
    }
  
    try {
      const response: any = await baseService.get(`${apiUrl.full_search}`,{
        params: {
          filter,  // Add the correct filter
          search: searchQuery,  // Add the search query from input
        }, // Pass the search query as a parameter
      });
      console.log('the search response is>>>',response)
      const data = response?.data; // Safely access the 'data' property
      if (data && Array.isArray(data)){
        // Assuming the response is an array of search results
        setSearchResults(data); // Set the search results to state
      } else {
        message.error("No results found");
      }
    } catch (error) {
      // Simplified error handling, checking for Axios error
      message.error("Failed to fetch search results. Please try again.");
      console.error("Search error:", error);
    }
  }

  return (
    <div className="flex justify-end gap-x-10 items-center">
      <div>
        {/* <div className="relative"> */}
          {/* <FiSearch className="absolute left-3 top-3 text-gray-500" />  */}
          {/* <input
             type="text"
             value={searchQuery}
             onChange={handleSearchChange} // Update search query state on input change
             onKeyDown={(e) => e.key === "Enter" && handleSearch()} // Trigger search on 'Enter' key
             placeholder="Search..."
            className="pl-10 pr-4 py-2 md:w-[20rem] lg:w-[25rem] border border-gray-300 rounded-lg  focus:border-dataColor"
          /> */}
        {/* </div> */}
         {/* Render search results if available */}
         {searchResults.length > 0 && (
          <div className="search-results mt-2 bg-white border border-gray-200 rounded-lg p-2">
            {searchResults.map((result, index) => (
              <div key={index} className="result-item p-2 border-b">
                {/* Render your search result details here */}
                <p>{result.name}</p> {/* Example display */}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex gap-x-2 items-center">
      <Avatar
          size={50}
          src={user?.profile?.logo}
          style={{ 
            backgroundColor: !user?.profile?.logo ? '#1677ff' : undefined,
            verticalAlign: 'middle',
            fontSize: '20px',
            fontWeight: 'bold'
          }}
        >
          {!user?.profile?.logo && getUserInitials(user?.fullName)}
        </Avatar>
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger>
                <div className="flex flex-col gap-y-0">
                  {/* Use displayName and displayRole */}
                  <p>{displayName}</p>
                  <small>{displayRole}</small>
                </div>
              </NavigationMenuTrigger>
              {/* Adjusted Content */}
              <NavigationMenuContent className="">
                <EditProfile />
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </div>
  );
}

