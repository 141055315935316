import { Avatar } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import CustomCard from "../../components/cards/CustomCard";
import MainLayout from "../../components/layouts/MainLayout";
import ProgressComponent from "../../components/spinners/ProgressComponent";
import Context from "../../context/context";
import baseService from "../../core/base-service";
import { apiUrl, baseUrl } from "../../core/url";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";

interface IMessageHistoryProps {
  id: number;
  message: string;
  type: string;
  created_at: string;
  sender: {
    id: number;
    fullName: string;
    profile: {
      id: number;
      user_id: number;
      logo: string | null;
    };
  };
  receiver: {
    id: number;
    fullName: string;
    profile: {
      id: number;
      user_id: number;
      logo: string | null;
    };
  };
  chatType: string;
  group_id?: number | null;
}

export default function MessageHistory() {
  const context = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<IMessageHistoryProps[]>([]);

  const getUserInitials = (fullName?: string) => {
    if (!fullName) return '';
    
    const names = fullName.trim().split(' ').filter(n => n);
    if (names.length >= 2) {
      return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`.toUpperCase();
    }
    return fullName.charAt(0).toUpperCase();
  };

  const columns: TableColumn<IMessageHistoryProps>[] = [
    {
      name: "Sender",
      cell: (row) => {
        const initials = getUserInitials(row.sender?.fullName);
        const logoUrl = row.sender?.profile?.logo;
        
        // Check for invalid URL patterns
        const isInvalidUrl = !logoUrl || 
          logoUrl.includes('149.248.8.220') || 
          /^[a-f0-9]{40}\.jpg$/.test(logoUrl) ||
          logoUrl === "https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg";
  
        return (
          <div className="flex items-center gap-2">
            <Avatar
              size={40}
              src={isInvalidUrl ? undefined : logoUrl}
              style={{ 
                backgroundColor: isInvalidUrl ? '#1677ff' : undefined,
                verticalAlign: 'middle',
                fontSize: '16px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
              }}
            >
              {isInvalidUrl && initials}
            </Avatar>
            <span>{row.sender?.fullName}</span>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <span className="px-2 py-1">
          <ArrowLongRightIcon className="size-6" />
        </span>
      ),
      sortable: true,
    },
    {
      name: "Recipient",
      cell: (row) => {
        const initials = getUserInitials(row.receiver?.fullName);
        const logoUrl = row.receiver?.profile?.logo;
        
        // Check for invalid URL patterns
        const isInvalidUrl = !logoUrl || 
          logoUrl.includes('149.248.8.220') || 
          /^[a-f0-9]{40}\.jpg$/.test(logoUrl) ||
          logoUrl === "https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg";
  
        return (
          <div className="flex items-center gap-2">
            <Avatar
              size={40}
              src={isInvalidUrl ? undefined : logoUrl}
              style={{ 
                backgroundColor: isInvalidUrl ? '#1677ff' : undefined,
                verticalAlign: 'middle',
                fontSize: '16px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
              }}
            >
              {isInvalidUrl && initials}
            </Avatar>
            <span>{row.receiver?.fullName}</span>
          </div>
        );
      },
      sortable: true,
    },
   
    {
      name: "Date and Time",
      selector: row => moment(row.created_at).format("MMM DD, YYYY HH:mm"),
      sortable: true,
      width: "180px",
    },
  ];

  async function getMessageHistory() {
    try {
      setLoading(true);
      const response:any = await baseService.get(`${apiUrl.message_history}`);
      if (response.data.code === 200) {
        setMessages(response.data.data);
        if (context) {
          context.setMessageHistoryState(response.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching message history:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMessageHistory();
  }, []);

  return (
    <MainLayout>
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl text-blue-700">Messages History</h1>
          <p className="text-gray-500 mt-1">
            View all messages exchanged within your organization
          </p>
        </div>
      </div>
      <CustomCard className="mt-5">
        <DataTable
          columns={columns}
          data={messages}
          progressPending={loading}
          progressComponent={<ProgressComponent />}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          highlightOnHover
          pointerOnHover
          striped
          responsive
          noDataComponent={
            <div className="flex flex-col items-center justify-center p-10">
              <p className="text-gray-500 text-lg">No Messages Available</p>
              <p className="text-gray-400 text-sm mt-2">Start a conversation to see messages here</p>
            </div>
          }
        />
      </CustomCard>
    </MainLayout>
  );
}