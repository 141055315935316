import { Navigate, Outlet } from "react-router-dom";
import StorageBox from "../../core/storage";
import { IWorkspace } from "../interface";
import { useEffect } from "react";

export default function PrivateRoute() {
  const companyDocs: IWorkspace | null = StorageBox.getCompanyDocs();
  const user = StorageBox.getUser();
  const token = StorageBox.getToken();

  // new one
   // Check if we're in development mode
  //  const isDev = process.env.REACT_APP_IS_DEV === 'true';

  //  if (isDev) {
  //    // Skip the auth checks in development mode
  //    return <Outlet />;
  //  }

  if (!token || !user || !companyDocs) {
    return <Navigate to="/signin" />;
  }

  const first_email_verified: number | undefined =
    companyDocs?.company_email_confirmations?.[0]?.first_email_verified;
  const second_email_verified: number | undefined =
    companyDocs?.company_email_confirmations?.[0]?.second_email_verified;

  const today = new Date();
  const expiredDate = companyDocs?.subscription?.expired;

  const subStatus =
    !expiredDate || new Date(expiredDate).getTime() > new Date().getTime();
  const planName = companyDocs?.subscription?.subscription_plan?.name || "";

  if (token !== null && user !== null && companyDocs !== null) {
    if (
      (first_email_verified === 0 || second_email_verified === 0) &&
      planName?.toLocaleUpperCase() !== "FREE"
    ) {
      return <Navigate to="/" />;

    } else if (planName?.toLocaleUpperCase() !== "FREE" && !subStatus) {
      return <Navigate to="/upload-billing-account"/>;
    } else {
      return <Outlet/>;
    }
  } else if (token !== null && user !== null) {
    return <Navigate to="/company-information"/>; 
  } else {
    return <Navigate to={`/`} />;
  }
}
