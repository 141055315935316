// export const baseUrl = "https://mytestdodo.pro/api"; //testing
export const baseUrl = "https://mylivedodo.pro/api";  //production

export const apiUrl = {
  /**Authentication */
  login: `${baseUrl}/auth/login`,
  signup: `${baseUrl}/company/workspace/users`,
  verify_email: `${baseUrl}/users/verify`,
  resend_otp: `${baseUrl}/users/otp/resend`,
  verify_email_one: `${baseUrl}/dodo/company/first/email/verify`,
  verify_email_two: `${baseUrl}/dodo/company/second/email/verify`,
  upload_company_documents: `${baseUrl}/company/workspaces`, //company/workspaces check out this
  get_company_documents: `${baseUrl}/dodo/company/my/verify/emails`,
  verify_both_email:`${baseUrl}/dodo/companies/verifies`,

  /**Subscription plans */
  sub_plans: `${baseUrl}/dodo/subscription/plans`,
  sub_plans_upgradable: `${baseUrl}/user/subscription/upgrade`,
  upgrade_plan: `${baseUrl}/user/subscription/plan/subscribe`,
  add_users_to_plan: `${baseUrl}/admin/subscription/add/users`,
  pay_for_plan: `${baseUrl}/subscription/payments`,
  pay_for_ads: `${baseUrl}/adds/subscription/payments`,
  verify_transaction: `${baseUrl}/subscription/payment/callback`,
  get_employees: `${baseUrl}/my/employees`,
  upgrade_storage_plan: `${baseUrl}/user/storage/subscription/plan/subscribe`,
  pay_storage_plan:`${baseUrl}/storage/subscription/payments`, // This include an id ${baseUrl}/storage/subscription/payments/{Subscription_id}

  extensions: `${baseUrl}/workspace/extensions`,
  delete_extensions: `${baseUrl}/admin/inactive/extension`,
  create_extensions: `${baseUrl}/admin/extensions`,
  create_extensions_multiple: `${baseUrl}/admin/extensions/imports`,
  update_extensions: `${baseUrl}/admin/extension`,
  dashboard: `${baseUrl}/admin/dashboard/statistics`,
  countries: `${baseUrl}/countries`,
  cities: `${baseUrl}/cities`,
  selected_language: `${baseUrl}/languages`,
  industries: `${baseUrl}/industries`,
  call_history: `${baseUrl}/workspace/call/histories`,
  message_history: `${baseUrl}/admin/messages`,
  // organization,admin,supervisor
  organizations: `${baseUrl}/my/workspaces`,
  supervisors: `${baseUrl}/dodo/companies/verifies`,
  edit_supervisor: `${baseUrl}/dodo/companies/supervisors`,
  search_user:`${baseUrl}/full/search`, // get request

  /**Ads */
  create_ads: `${baseUrl}/dodo/advertisments`,
  get_ads: `${baseUrl}/dodo/workspace/advertisments`,

  /**Notice board */
  get_notice_board: `${baseUrl}/dodo/company/notice/boards`,
  create_notice_board: `${baseUrl}/dodo/notice/boards`,

  file:`${baseUrl}/upload/files`, ///upload/files

  /**Billing */
  billing: `${baseUrl}/payments`,

  /**Storage */
  storage_statistics: `${baseUrl}/dodo/company/storage/statistics`,
  storage_plans: `${baseUrl}/dodo/storage/plans`,
  file_history: `${baseUrl}/dodo/my/uploads`,

  // admin user
  admin_user: `${baseUrl}/company/workspace/admin/users`,
  admin_user_update: `${baseUrl}/admin/info/update`,
  get_admin_users:  `${baseUrl}/dodo/companies/admins`,
  add_admin_user:   `${baseUrl}/dodo/companies/add/admins/`, // this include an id ${baseUrl}/dodo/companies/add/admins/{id}
  remove_admin_user: `${baseUrl}/dodo/companies/remove/admins`, // this include an id ${baseUrl}/dodo/companies/remove/admins/{id}

  // group
  company_groups: `${baseUrl}/dodo/companies/groups`,
  remove_user_from_group: `${baseUrl}/dodo/group/remove`, // this include an id ${baseUrl}dodo/group/remove/{id}
  approve_group: `${baseUrl}/dodo/companies/groups/approval/`,// this include an id ${baseUrl}/dodo/groups/approve/{id}
  group_full_search: `${baseUrl}/full/search`, // This include an id ${baseUrl}/dodo/groups/full/search?{id}

  // Performing a Full Search 
  full_search:`${baseUrl}/full/search`
};
