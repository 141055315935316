interface CheckProgressProps {
    progress: number; // Determines how many steps are "active"
  }
  
  export const CheckProgress: React.FC<CheckProgressProps> = ({ progress }) => {
    return (
      <div>
        <ul className="flex gap-x-2">
          {[...Array(5)].map((_, index) => (
            <li
              key={index}
              className={`w-20 text-3xl font-bold  border-2 ${index < progress ? "border-[#304CE0]" : "border-gray-300"
                }`}
            ></li>
          ))}
        </ul>
      </div>
    );
  };