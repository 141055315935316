import React from "react";
import CustomSidebar from "../navigation/CustomSidebar";
import TopNavBar from "../navigation/TopNavBar";

export default function MainLayout({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className="flex flex-row h-screen">
      <CustomSidebar />
      <div className="min-h-screen max-h-screen overflow-x-scroll flex-1">
        <div className="p-3">
          <TopNavBar />
          {children}
          <div className="flex justify-center mt-10">
            <p>
              Copyright @ Dodo Technologies Ltd | All Rights Reserved | 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
