import { toast } from "react-toastify";

export function displayInfoAlert(message: string) {
  toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function displayErrorAlert(message: string) {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function displaySuccessAlert(message: string) {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function displayWarningAlert(message: string) {
  toast.warn(message, {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

interface ConfirmationProps {
  confirmationMessage: string;
  onConfirmFunction: () => void;
}

export const showConfirmationAlert = ({
  confirmationMessage,
  onConfirmFunction,
}: ConfirmationProps): void => {
  toast.warning(
    () => (
      <div className="flex flex-col items-center">
        <p className="mb-4">{confirmationMessage}</p>
        <div className="flex space-x-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              onConfirmFunction();
              toast.dismiss();
            }}
          >
            Confirm
          </button>
          <button
            className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
            onClick={() => toast.dismiss()}
          >
            Cancel
          </button>
        </div>
      </div>
    ),
    {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      draggable: false,
      hideProgressBar: true,
      position: "top-center",
    }
  );
};
