import React, { useState, useEffect } from 'react';
import { Col, Input, Row, Spin, message } from "antd";
import { GiPencil } from 'react-icons/gi';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTrigger,
} from "../../componentUI/components/ui/dialog";
import baseService from "../../core/base-service";
import { apiUrl } from "../../core/url";
import StorageBox from "../../core/storage";
// import SelectCountry from "../../components/form/SelectCountry";
import { displayErrorAlert, displaySuccessAlert } from "../../components/alert/alert";

export const EditProfile = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [adminData, setAdminData] = useState({
        fullName: '',
        email: '',
        gender: '',
        department: '',
        position: '',
    });

    useEffect(() => {
        // Fetch admin data when component mounts
        const user = StorageBox.getUser();
        if (user) {
            setAdminData({
                fullName: user.fullName || '',
                email: user.email || '',
                gender: user.gender || '',
                department: user.department || '',
                position: user.position || '',
               
            });
        }
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setAdminData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async () => {
        try {
            if (adminData.fullName === "") {
                displayErrorAlert("Please input a name");
                return;
            }
            if (adminData.email === "") {
                displayErrorAlert("Please input an email");
                return;
            }
            if (adminData.gender === "") {
                displayErrorAlert("Please input your gender");
                return;
            }
          
            if (adminData.department === "") {
                displayErrorAlert("Please input a department");
                return;
            }
            // if (adminData.role === "") {
            //     displayErrorAlert("Please input a role");
            //     return;
            // }

            setLoading(true);
            const user = StorageBox.getUser();
            if (user && user.id) {
                const response: any = await baseService.put(`${apiUrl.admin_user_update}`, adminData);
                console.log("the response from the admin update", response);
                displaySuccessAlert(response.data?.message ?? "Admin details updated successfully");
                // Update local storage with new data
                StorageBox.setUser({ ...user, ...adminData });
                setOpen(false);
            }
        } catch (error: any) {
            console.error("Error updating admin data:", error);
            displayErrorAlert(error.response?.data?.message ?? "Failed to update admin details");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog modal={true} open={open} onOpenChange={setOpen}>
            <DialogTrigger className="w-full" asChild>
                <div className="admin flex bg-white rounded-lg items-center shadow-md py-3 px-4 w-full max-w-[200px] cursor-pointer hover:bg-gray-100 transition-all">
                    <span className="mr-2">
                        <GiPencil size={20} />
                    </span>
                    <p className="text-sm font-medium truncate">Edit</p>
                </div>
            </DialogTrigger>

            <DialogContent 
             onInteractOutside={(e) => {
                e.preventDefault();
            }}
            onEscapeKeyDown={(e) => {
                e.preventDefault();
            }}
            >
                <DialogHeader>
                    <DialogDescription>
                        <Row className="p-5">
                            <Col span={24}>
                                <div className="flex flex-col max-h-fit font-poppins">
                                    <div className='flex justify-start text-left'>
                                        <p className="text-lg font-semibold">
                                            Admin Details
                                        </p>
                                    </div>
                                    <div className='w-full border my-2'></div>
                                    <div className="w-full mt-auto flex flex-col justify-center items-center">
                                        <div className="mt-1 w-full">
                                            <label htmlFor="fullName">Full name</label>
                                            <Input
                                                name="fullName"
                                                placeholder="Enter your full name"
                                                value={adminData.fullName}
                                                onChange={handleInputChange}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="mt-5 w-full">
                                            <label htmlFor="email">Email Address</label>
                                            <Input
                                                name="email"
                                                placeholder="Enter your corporate email"
                                                value={adminData.email}
                                                onChange={handleInputChange}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="mt-5 w-full">
                                            <label htmlFor="gender">Gender</label>
                                            <Input
                                                name="gender"
                                                placeholder="Enter Your gender"
                                                value={adminData.gender}
                                                onChange={handleInputChange}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="mt-5 w-full">
                                            <label htmlFor="department">Department</label>
                                            <Input
                                                name="department"
                                                placeholder="Enter your Department"
                                                value={adminData.department}
                                                onChange={handleInputChange}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="mt-5 w-full">
                                            <label htmlFor="position">Position</label>
                                            <Input
                                                name="position"
                                                placeholder="Enter your Position"
                                                value={adminData.position}
                                                onChange={handleInputChange}
                                                className="w-full"
                                            />
                                        </div>
                                       
                                        <div className="my-5 flex flex-col gap-y-2 w-full">
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center font-bold py-2 px-24 rounded-xl w-full"
                                                onClick={handleSubmit}
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <Spin />
                                                ) : (
                                                    <p className="flex items-center gap-x-2">
                                                        Save
                                                    </p>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};