import {
  ArrowLeftStartOnRectangleIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link, Location, useLocation, useNavigate } from "react-router-dom";
import Context from "../../context/context";
import StorageBox from "../../core/storage";
import navigationLinks from "./navigation-links";
import { showConfirmationAlert } from "../alert/alert";
import { Tooltip } from "antd";
import logo from "./../../assets/images/logo.png";

export default function CustomSidebar() {
  const location: Location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const { sidebarVisible, setSidebarVisible } = useContext(Context);

  const [openSubMenu, setOpenSubMenu] = useState(path.includes("history"));
  const [openOrgSubMenu, setOpenOrgSubMenu] = useState(path.includes("user-") || path.includes("organization"));

  return (
    <Sidebar
      transitionDuration={800}
      collapsed={sidebarVisible}
    >
      {sidebarVisible ? null : (
        <div
          className="max-h-[100px] min-h-[100px] flex justify-center items-center cursor-pointer"
          onClick={() => setSidebarVisible(!sidebarVisible)}>
          <img alt="dodo-user" src={logo} className="h-10" />
        </div>
      )}
      <Menu
        menuItemStyles={{
          button: sidebarVisible
            ? {
                [`&.ps-active`]: {
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                },
                color: "black",
                "&:hover": {
                  color: "black",
                },
              }
            : {
                [`&.ps-active`]: {
                  backgroundColor: "#9dbff2",
                  color: "blue",
                  fontWeight: "bold",
                },
                borderRadius: 20,
                margin: 20,
                color: "black",
                fontSize: 18,
                fontWeight: "bolder",
                "&:hover": {
                  color: "black",
                },
              },
        }}
      >
        {sidebarVisible ? (
          <MenuItem
            icon={<Bars3Icon className={"w-5 h-5"} />}
            onClick={() => setSidebarVisible(!sidebarVisible)}
          ></MenuItem>
        ) : null}
        {navigationLinks.map((_menu_item, i) => {
          const { name, Icon, link, disabled } = _menu_item;
          
          if (link === "/billing") {
            return (
              <React.Fragment key={i}>
                <hr className="mx-10"/>
                <MenuItem
                  active={`/` + path.split("/")?.[1] === link}
                  icon={
                    <Tooltip title={name}>
                      <Icon className="w-5 h-5" />
                    </Tooltip>
                  }
                  disabled={disabled}
                  component={<Link to={link} />}
                >
                  <p className="font-light text-sm">{name}</p>
                </MenuItem>
              </React.Fragment>
            );
          }

          if (_menu_item.subLinks) {
            const isHistory = name === "History";
            const isOrganization = name === "Organization";
            
            if (isOrganization) {
              return (
                <SubMenu
                  key={i}
                  icon={
                    <Tooltip title={name}>
                      <Icon className="w-5 h-5" />
                    </Tooltip>
                  }
                  label={<p className="font-light text-sm">{name}</p>}
                  open={openOrgSubMenu}
                  onOpenChange={(open) => {
                    setOpenOrgSubMenu(open);
                  }}
                >
                  {_menu_item.subLinks.map((_sub, ii) => (
                    <MenuItem
                      key={ii}
                      active={`/` + path.split("/")?.[1] === _sub.link.split("/")?.[1]}
                      component={<Link to={_sub.link} />}
                      icon={<_sub.icon className="w-5 h-5" />}
                    >
                      <p className="font-light text-sm">{_sub.name}</p>
                    </MenuItem>
                  ))}
                </SubMenu>
              );
            }
            
            return (
              <SubMenu
                icon={
                  <Tooltip title={name}>
                    <Icon className="w-5 h-5" />
                  </Tooltip>
                }
                label={<p className="font-light text-sm">{name}</p>}
                open={isHistory ? openSubMenu : false}
                onClick={() => {
                  if (isHistory) {
                    setOpenSubMenu(!openSubMenu);
                  }
                }}
                key={i}
              >
                {_menu_item.subLinks.map((_sub, ii) => (
                  <MenuItem
                    active={`/` + path.split("/")?.[1] === _sub.link.split("/")?.[1]}
                    component={<Link to={_sub.link} />}
                    icon={<_sub.icon className="w-5 h-5" />}
                    key={ii}
                  >
                    <p className="font-light text-sm">{_sub.name}</p>
                  </MenuItem>
                ))}
              </SubMenu>
            );
          }

          return (
            <MenuItem
              active={`/` + path.split("/")?.[1] === link}
              icon={
                <Tooltip title={name}>
                  <Icon className="w-5 h-5" />
                </Tooltip>
              }
              disabled={disabled}
              key={i}
              component={<Link to={link} />}
            >
              <p className="font-light text-sm">{name}</p>
            </MenuItem>
          );
        })}
        <MenuItem
          className="mt-[3vh]"
          icon={
            <ArrowLeftStartOnRectangleIcon className="w-5 h-5 text-red-700" />
          }
          onClick={() =>
            showConfirmationAlert({
              confirmationMessage: "Are you sure you want to logout?",
              onConfirmFunction: () => {
                StorageBox.deleteToken();
                navigate("/");
              },
            })
          }>
          <p className="font-light text-sm">Logout</p>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}